import React from 'react';

// Utils
import {usePreHeaderBgSx, useSubFooterBgSx} from '../hooks';


// Components
import {Base} from '../../ui/layouts';
import {Badge, FlexCol, FlexInline} from '../../ui/components';
import {PageContextProvider} from '../contexts';
import {ArticlePagination, Footer, Head, Header, PreHeader, SubFooter} from '../components';

const SPACING = ['md', 'md', 'md', 'lg'];

// Markup
const MdxTags = (props) => {
    const {tags} = props.pageContext;

    const preHeaderBgSx = usePreHeaderBgSx();
    const subFooterBgSx = useSubFooterBgSx();

    return (
        <PageContextProvider value={props.pageContext}>
            <Head
                meta={{
                    title: 'Kategorien',
                    description: 'Alle Artikel-Kategorien im Überblick',
                    canonical: '/tags/'
                }}
            />
            <Base.Container>
                <Base.PreHeader sx={preHeaderBgSx}>
                    <PreHeader/>
                </Base.PreHeader>
                <Base.Header>
                    <Header/>
                </Base.Header>
                <Base.Body
                    px={['sm']}
                    py={SPACING}
                >
                    <FlexCol
                        width="100%"
                        maxWidth="3xl"
                        space={SPACING}
                        alignX="stretch"
                        alignY="top"
                        sx={{
                            alignSelf: 'stretch'
                        }}
                    >
                        <ArticlePagination
                            title="Kategorien"
                            pageInfo={{}}
                        />

                        {tags.length > 1 ? (
                            <FlexInline space="sm" alignX="center">
                                {tags.map(tag => (
                                    <Badge
                                        key={tag.page.key}
                                        as="a"
                                        href={tag.page.pathname}
                                        bg="whisper"
                                        size={3}
                                        sx={{
                                            ':hover': {
                                                cursor: 'pointer',
                                                bg: 'primary',
                                                color: 'textOnPrimary'
                                            }
                                        }}
                                    >
                                        {tag.meta.title}
                                    </Badge>
                                ))}
                            </FlexInline>
                        ) : null}
                    </FlexCol>
                </Base.Body>
                <Base.Footer>
                    <Footer/>
                </Base.Footer>
                <Base.SubFooter sx={subFooterBgSx}>
                    <SubFooter/>
                </Base.SubFooter>
            </Base.Container>
        </PageContextProvider>
    );
};

export default MdxTags;

